<template>
  <div class="calendar-select">
    <div class="date-select-item" @click="dateSelect('date')">
      <div class="date">{{ date }}</div>
      <van-icon name="arrow-down" />
    </div>
    <van-calendar
      ref="vanCalendar"
      v-if="calendarShow"
      v-model="calendarShow"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm="confirmDate($event, 'day')"
      :default-date="defaultDate"
    />
    <datetime-picker
      :show.sync="datePickerShow"
      v-model="tmpDate"
      :min-date="minDate"
      :max-date="maxDate"
      :type="type"
      @confirm="confirmDate($event, type)"
    ></datetime-picker>
  </div>
</template>

<script>
import datetimePicker from '@/components/popup/datetime.vue'
import { formatTime } from '@/utils/date'
export default {
  name: 'calendarSelect',
  components: { datetimePicker },
  props: {
    date: {
      // sync
      type: String,
    },
    type: {
      default: 'calendar',
      // default: 'picker',
      type: String,
    },
  },
  data() {
    return {
      calendarShow: false,
      datePickerShow: false,
      tmpDate: undefined,
    }
  },
  computed: {
    minDate() {
      return new Date(new Date().getFullYear() - 3, 1, 1)
    },
    maxDate() {
      return new Date(new Date().getFullYear() + 3, 1, 1)
    },
    defaultDate() {
      let date = this.date
      if (date) {
        return new Date(date.replace(/-/g, '/'))
      } else {
        return new Date()
      }
    },
  },
  watch: {
    date() {
      this.tmpDate = this.date
    },
  },
  methods: {
    dateSelect() {
      if (this.type == 'calendar') {
        this.calendarShow = true
      } else if (this.type == 'year-month') {
        this.datePickerShow = true
      }
    },
    confirmDate(e, type) {
      let date = undefined
      switch (type) {
        case 'day':
          {
            date = formatTime(new Date(e), '-').slice(0, 10)
            this.calendarShow = false
          }
          break
        case 'year-month':
          {
            date = this.$dayjs(e).format('YYYY-MM')
            this.datePickerShow = false
          }
          break
      }
      this.$emit('update:date', date)

      this.$emit('change')
    },
  },
}
</script>

<style lang="less" scoped>
.calendar-select {
  display: flex;
  align-items: center;

  .date-select-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;
    height: 2.5rem;
    border: 1px solid #eeeeee;
    border-radius: 0.5rem;
    box-sizing: border-box;
    font-size: 0.875rem;
    .van-icon-arrow-down {
      font-size: 0.875rem;
    }
  }
}
</style>

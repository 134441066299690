<template>
  <div class="main">
    <yy-navbar></yy-navbar>
    <yiyu-form
      :_this="thisAlias"
      :form="form"
      :formOpts="formOpts"
      @submit="onSubmit"
      ref="form"
      class="relative"
      v-myLoad="isLoading"
    >
      <template v-for="(item, index) in form.position">
        <template :slot="'del' + index">
          <div
            class="main-c left"
            :key="index"
            @click="delPosition(index)"
            v-if="form.position.length != 1"
          >
            删除地址
          </div>
        </template>
      </template>
      <template #footer>
        <div class="add-position main-c" @click="addPosition">新增多个地点</div>
        <fixed-btn
          :btnTxt="status == 'add' ? '确认新增' : '保存'"
          @click="toSubmit"
          :loading="btnLoading"
          :disabled="btnLoading"
        ></fixed-btn>
      </template>
    </yiyu-form>

    <keep-alive>
      <b-person-picker
        ref="personPicker"
        title="选择考勤人员"
        :single="false"
        :cur-key.sync="form.members"
        :cur-name.sync="form.members_value"
        keyName="user_key"
        @confirm="toConfirmChangeGroup"
      ></b-person-picker>
    </keep-alive>
    <keep-alive>
      <b-person-picker
        ref="personPicker2"
        title="选择组负责人"
        :single="true"
        :cur-key.sync="form.charge"
        :cur-name.sync="form.charge_value"
        keyName="user_key"
      ></b-person-picker>
    </keep-alive>
    <datetime-popup
      :date.sync="form.attend_range.start"
      :show.sync="attendStartShow"
      title="选择上班时间"
      @confirm="attendStartShow = false"
      @cancel="attendStartShow = false"
      type="time"
    ></datetime-popup>
    <datetime-popup
      :date.sync="form.attend_range.end"
      :show.sync="attendEndShow"
      title="选择下班时间"
      @confirm="attendEndShow = false"
      @cancel="attendEndShow = false"
      type="time"
    ></datetime-popup>
    <datetime-popup
      :date.sync="form.rest_range.start"
      :show.sync="restStartShow"
      title="选择休息开始时间"
      @confirm="restStartShow = false"
      @cancel="restStartShow = false"
      type="time"
    ></datetime-popup>
    <datetime-popup
      :date.sync="form.rest_range.end"
      :show.sync="restEndShow"
      title="选择休息结束时间"
      @confirm="restEndShow = false"
      @cancel="restEndShow = false"
      type="time"
    ></datetime-popup>
    <van-dialog
      v-model="changeGroupShow"
      title="提醒"
      show-cancel-button
      @confirm="confirmChangeGroup"
    >
      <span
        >以下成员已归属了其他考勤组，此处选择该成员后，将改变成员归属考勤组，是否确认修改？</span
      >
      <van-checkbox-group v-model="confirmMembers" ref="checkboxGroup">
        <van-checkbox
          :name="item.user_key"
          v-for="(item, index) in unConfirmMembers"
          :key="index"
          >{{ item.nickname }}({{ item.g_name }})</van-checkbox
        >
      </van-checkbox-group>
      <van-button type="primary" @click="checkAll">全选</van-button>
      <van-button type="info" @click="toggleAll">反选</van-button>
    </van-dialog>
  </div>
</template>

<script>
import datetimePopup from '@/components/popup/datetime.vue';
import bPersonPicker from '@/bComponents/bPersonPicker.vue';
import yiyuForm from '@/components/form/yiyuForm.vue';
import fixedBtn from '@/components/button/fixedBtn';
import { mapState } from 'vuex';
import { deepCopy } from '@/utils/utils';
export default {
  name: 'attendGroupEdit',
  components: {
    yiyuForm,
    fixedBtn,
    bPersonPicker,
    datetimePopup,
  },
  data() {
    return {
      thisAlias: this,
      status: undefined,
      isLoading: false,
      btnLoading: false,
      form: {
        atg_key: undefined,
        type: 1, // 考勤类型
        name: undefined, // 组名
        charge: undefined, // 负责人key
        charge_value: undefined, // 负责人key
        attend_range: {
          start: undefined,
          end: undefined,
        }, // 考勤时间
        field: 2, // 是否可外勤
        position: [
          {
            id: undefined,
            addr: undefined, // "地址名称"
            latitude: undefined, // "纬度"
            longitude: undefined, // "经度"
            distance: undefined, // "打卡范围"
          },
        ], // 打卡地点
        distance: undefined, // 打卡范围
        members: [], // 成员
        members_value: undefined,
        rest_range: {
          start: undefined,
          end: undefined,
        }, // 休息时间
        compensation: undefined, // 最多补卡次数
      },
      attendStartShow: false,
      attendEndShow: false,
      restStartShow: false,
      restEndShow: false,
      curItem: {},
      curIndex: undefined,
      curHandle: undefined, // addPositon
      attend_user: [], // 所有成员
      confirmMembers: [], // 确认修改的成员
      unConfirmMembers: [], //
      changeGroupShow: false,
    };
  },
  computed: {
    ...mapState({
      typeObj: (state) => state.attendance.groupType,
    }),
    formOpts() {
      let _this = this;
      return [
        {
          title: '',
          child: [
            {
              required: true,
              colon: true,
              formType: 'radio',
              value: this.form.type ? String(this.form.type) : undefined,
              name: 'type',
              label: '考勤类型',
              opts: this.typeObj,
            },
            {
              label: '考勤人员',
              required: true,
              formType: 'select',
              value: this.form.members_value,
              name: 'members_value',
              click() {
                _this.$refs['personPicker'].show = true;
              },
            },
            {
              label: '组名称',
              required: true,
              value: this.form.name,
              name: 'name',
            },
            {
              label: '组负责人',
              required: true,
              formType: 'select',
              value: this.form.charge_value,
              name: 'charge_value',
              click() {
                _this.$refs['personPicker2'].show = true;
              },
            },
          ],
        },
        {
          title: '',
          show: this.form.type == 1,
          child: [
            {
              label: '上班时间',
              required: true,
              formType: 'select',
              value: this.form.attend_range.start,
              name: 'attend_range',
              click() {
                _this.attendStartShow = true;
              },
              show: this.form.type == 1,
            },
            {
              label: '下班时间',
              required: true,
              formType: 'select',
              value: this.form.attend_range.end,
              name: 'attend_range',
              click() {
                _this.attendEndShow = true;
              },
              show: this.form.type == 1,
            },
            {
              label: '休息开始',
              formType: 'select',
              value: this.form.rest_range.start,
              name: 'rest_range',
              click() {
                _this.restStartShow = true;
              },
            },
            {
              label: '休息结束',
              formType: 'select',
              value: this.form.rest_range.end,
              name: 'rest_range',
              click() {
                _this.restEndShow = true;
              },
            },
          ],
        },
        {
          title: '',
          child: [
            {
              label: '补卡次数',
              value: this.form.compensation,
              name: 'compensation',
              formType: 'number',
            },
            {
              formType: 'radio',
              required: true,
              label: '允许外勤打卡',
              value: this.form.field ? String(this.form.field) : undefined,
              name: 'field',
              opts: {
                1: '是',
                2: '否',
              },
            },
          ],
        },
        {
          title: '',
          child: this.areaOpts,
        },
      ];
    },
    areaOpts() {
      let arr = [];
      if (Array.isArray(this.form.position)) {
        this.form.position.forEach((item, index) => {
          let tmp = [
            // {
            //   required: true,
            //   label: '地点选择',
            //   formType: 'select',
            //   value: item.addr,
            //   valueKey: ['form', 'position', index, 'addr'],
            //   click: () => {
            //     this.curIndex = index
            //     this.curHandle = 'editPosition'
            //     this.toPage('area', item)
            //   },
            // },
            {
              required: true,
              label: '地点名称',
              formType: 'input',
              value: item.addr,
              valueKey: ['form', 'position', index, 'addr'],
            },
            {
              required: true,
              label: '经度',
              formType: 'input',
              value: item.longitude,
              valueKey: ['form', 'position', index, 'longitude'],
            },
            {
              required: true,
              label: '纬度',
              formType: 'input',
              value: item.latitude,
              valueKey: ['form', 'position', index, 'latitude'],
            },
            {
              required: true,
              label: '距离范围',
              formType: 'number',
              value: item.distance,
              valueKey: ['form', 'position', index, 'distance'],
              bottomSlot: 'del' + index,
              extra: '米',
            },
          ];
          arr.push(...tmp);
        });
      }

      return arr;
    },
  },
  mounted() {
    console.log('mounted');
    if (this.$route.query) {
      let query = this.$route.query;
      this.form.atg_key = query.atg_key;
      if (this.form.atg_key) {
        this.status = 'edit';
        this.getData();
      } else {
        this.status = 'add';
      }
      this.getMember();
    }
  },
  activated() {
    if (sessionStorage['yy-cur-map']) {
      let position = JSON.parse(sessionStorage['yy-cur-map']);
      let { addr, lat, lng, radius } = position;
      let tmp = {
        addr: addr, // "地址名称"
        latitude: lat, // "纬度"
        longitude: lng, // "经度"
        distance: radius, // "打卡范围"
      };
      sessionStorage.removeItem('yy-cur-map');
      if (this.curHandle == 'addPosition') {
        this.form.position.push(tmp);
      } else if (this.curHandle == 'editPosition') {
        this.form.position[this.curIndex] = tmp;
        this.form = deepCopy(this.form);
      }
    }
  },
  methods: {
    getMember() {
      this.$get('/attend_user/getMember', {}).then((res) => {
        if (res.code == 10000) {
          this.attend_user = res.data.list;
        }
      });
    },
    getData() {
      this.isLoading = true;
      this.$get('/attend_group/getGroup', {
        atg_key: this.form.atg_key,
      }).then((res) => {
        if (res.code === 10000) {
          let data = res.data;
          this.isLoading = false;
          for (let key in this.form) {
            data[key] = data[key] || this.form[key];
          }
          let members = [];
          let membersValue = [];
          if (Array.isArray(data.members)) {
            data.members.forEach((item) => {
              if (Array.isArray(item.list)) {
                item.list.forEach((item1) => {
                  members.push(item1.user_key);
                  membersValue.push(item1.nickname);
                });
              }
            });
          }
          data.members = [...new Set(members)];
          data.members_value = [...new Set(membersValue)].join('、');
          this.form = data;
        }
      });
    },
    toPage(type, item = {}) {
      switch (type) {
        case 'area':
          this.$toPage(this, 'attendanceArea', {
            lng: item.longitude,
            lat: item.latitude,
            radius: item.distance,
          });
          break;
      }
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit();
    },
    onSubmit() {
      this.$post('/attend_group/addGroup', this.form).then((res) => {
        if (res.code == 10000) {
          this.$toast.success(this.status == 'add' ? '新增成功' : '编辑成功');
          // 修改后要看看用户还在不在考勤组
          this.$store.dispatch('getAttendInfo');
          this.$go(-1, this);
        }
      });
    },
    delPosition(index) {
      this.form.position.splice(index, 1);
    },
    addPosition() {
      // this.curHandle = 'addPosition'
      // this.toPage('area')
      this.form.position.push({
        id: undefined,
        addr: undefined, // "地址名称"
        latitude: undefined, // "纬度"
        longitude: undefined, // "经度"
        distance: undefined, // "打卡范围"
      });
    },
    // 如果考勤人员在其他组，需要告知用户
    toConfirmChangeGroup() {
      let unConfirmKeys = [];
      this.unConfirmMembers = this.attend_user.filter((item) => {
        if (!item.atg_key) return false;
        else if (this.form.atg_key == item.atg_key) return false;
        else if (this.form.members.includes(item.user_key)) {
          unConfirmKeys.push(item.user_key);
          return true;
        } else return false;
      });

      if (this.unConfirmMembers.length) {
        this.form.members = this.form.members.filter(
          (item) => !unConfirmKeys.includes(item)
        );
        this.changeGroupShow = true;
      }
    },
    confirmChangeGroup() {
      this.form.members.push(...this.confirmMembers);
      this.confirmMembers = [];
      this.changeGroupShow = false;
    },
    checkAll() {
      this.$refs.checkboxGroup.toggleAll(true);
    },
    toggleAll() {
      this.$refs.checkboxGroup.toggleAll();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.yiyu-box-section {
  margin: 0 !important;
}

.add-position {
  .FlexCenter;
  margin: 1rem;
}

.left {
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: right;
}
</style>

<template>
  <div class="info">
    <div class="info-left" @click="$emit('clickLeft')">
      <yiyu-avator
        :value="datas.nickname && datas.nickname.slice(-2)"
        size="2.8rem"
        font-size="1rem"
      ></yiyu-avator>
      <div class="info-txt">
        <div class="info-txt-top">
          <span class="name">{{ datas.nickname }}</span>
          <span
            v-for="(item, index) in datas.roles"
            :key="index"
            :class="{
              red: item.role == 1,
              purple: item.role == 2,
              green: [5, 6, 7].includes(Number(item.role)),
              orange: [3, 4].includes(Number(item.role)),
              blue: [8, 9].includes(Number(item.role)),
              brown: [10, 11, 12].includes(Number(item.role)),
              tag: true,
            }"
            >{{ item.role_value }}</span
          >
        </div>
        <div class="info-txt-bottom text-c7">
          {{ subText }}
        </div>
        <slot name="bottom"></slot>
      </div>
    </div>
    <slot name="right"></slot>
  </div>
</template>

<script>
import mcMixin from '@/mixin/mcMixin'
import yiyuAvator from '@/components/show/avator'
import { deepCopy } from '@/utils/utils'
import { mapState } from 'vuex'
export default {
  name: 'info',
  components: { yiyuAvator },
  mixins: [mcMixin],
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    subKey: {
      type: String,
      default: 'account', // 'mc'
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      roleObj: (store) => store.basic.roleObj,
    }),
    datas() {
      let obj = deepCopy(this.info)
      if (Array.isArray(obj.roles)) {
        obj.roles = obj.roles.map((item) => {
          if (typeof item == 'object') return item
          else return { role: item, role_value: this.roleObj[item] }
        })
      }
      return obj
    },
    subText() {
      if (this.subKey == 'mc') {
        return this.getMcNames(this.info.mc_list) || '暂无负责基地'
      } else if (this.subKey == 'group') {
        return this.info?.group?.name
      } else {
        return this.info[this.subKey]
      }
    },
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .info-left {
    display: flex;
    align-items: center;

    .info-txt {
      margin-left: 8px;

      .info-txt-top {
        display: flex;
        align-items: center;
        .name {
          color: @titleColor;
          font-size: 1.1rem;
          max-width: 6rem;
          .Ellipsis;
        }

        .tag {
          padding: 1px 8px;
          font-size: 10px;
          margin-left: 8px;
          color: #fff;
          border-radius: 2px;
          background: linear-gradient(89deg, #00b9b9 0%, #00cba0 100%);
        }

        .purple {
          background: linear-gradient(89deg, #9e46ff 0%, #ceabfe 100%);
        }
        .green {
          background: linear-gradient(89deg, #00b9b9 0%, #00cba0 100%);
        }
        .orange {
          background: linear-gradient(89deg, #ffb400 0%, #ffd800 100%);
        }
        .blue {
          background: linear-gradient(89deg, #00d2f1 0%, #00e7eb 100%);
        }
        .red {
          background: linear-gradient(
            89deg,
            hsl(14, 100%, 47%) 0%,
            #eb8d7c 100%
          );
        }
      }

      .info-txt-bottom {
        font-size: 1rem;
        .Ellipsis;
        width: 50vw;
      }
    }
  }

  .status {
    white-space: nowrap;
    .status-item-invite {
      font-weight: 500;
      color: @mainColor;
      font-size: 1rem;
      border: 1px solid @mainColor;
      background: rgba(@mainRgb, 0.08);
      padding: 3px 8px;

      &.green {
        color: @green;
        background: rgba(0, 177, 114, 0.08);
        border-color: @green;
      }
    }
    .status-item-leave {
      font-weight: 500;
      color: @textColor;
      opacity: 0.7;
      font-size: 1rem;
      border: 1px solid @lineColor;
      background: @bgColor;
      padding: 3px 8px;
    }
  }
}
</style>

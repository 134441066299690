import { render, staticRenderFns } from "./clockDetail.vue?vue&type=template&id=0cdb8ae6&scoped=true&"
import script from "./clockDetail.vue?vue&type=script&lang=js&"
export * from "./clockDetail.vue?vue&type=script&lang=js&"
import style0 from "./clockDetail.vue?vue&type=style&index=0&id=0cdb8ae6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cdb8ae6",
  null
  
)

export default component.exports
<template>
  <div class="main">
    <yy-navbar></yy-navbar>
    <div class="main-content">
      <section class="header row-between section row-align-center">
        <div class="left title">
          <calendar-select
            :date.sync="curDate"
            @change="getData"
          ></calendar-select>
        </div>
        <div class="right handles">
          <div class="handle-btn" @click="changeDate(-1)">前一天</div>
          <div class="handle-btn" @click="changeDate(1)">后一天</div>
        </div>
      </section>
      <div class="relative" v-myLoad="isLoading">
        <section>
          <div class="row">
            <div class="label">
              规则：
              <span v-if="group.type == 1"
                >固定时间上下班{{
                  group.attend_range.start && group.attend_range.end
                    ? getTimeRange(group.attend_range)
                    : ''
                }}</span
              >
              <span v-else>自由上下班</span>
            </div>
          </div>
          <div class="row">
            <span class="value"
              >{{ group.field == 1 ? '允许' : '不允许' }}外勤打卡</span
            >；
            <span class="value" v-if="group.compensation"
              >本月还可补卡{{ group.sur_com }}次</span
            >
            <span v-else>补卡次数无限制</span>
          </div>
        </section>
        <section class="clock-record">
          <time-line-top
            v-for="(item, index) in total_list"
            :key="index"
            :time="item.time"
            :class="{ 'text-c7': !isVaild(item, index) }"
          >
            <template #right>
              <template v-if="isVaild(item, index)">
                <status-tag
                  :obj="attendStatus"
                  v-model="item.status"
                ></status-tag>
                <status-tag
                  :obj="attendType"
                  v-model="item.attend_type"
                  v-if="item.attend_type == 2"
                ></status-tag>
              </template>
            </template>
            <template #time>
              <span class="grey" v-if="!isVaild(item, index)"
                >(无效打卡，此记录已被更新)</span
              >
            </template>
            <template #content>
              <div class="row">
                <div class="label">地点：</div>
                <div class="value">
                  {{
                    (item.position &&
                      `经度(${item.position.longitude})纬度(${item.position.latitude})`) ||
                    '暂无'
                  }}
                </div>
              </div>
              <div class="row" v-if="item.remark">
                <div class="label">备注：</div>
                <div class="value">{{ item.remark }}</div>
              </div>
              <img-uploader
                :is-show="true"
                result-type="file"
                :url="item.img"
              ></img-uploader>
            </template>
          </time-line-top>
        </section>
        <empty v-if="!total_list.length">暂无打卡数据</empty>
      </div>
    </div>
  </div>
</template>

<script>
import empty from '@/components/layout/empty.vue';
import imgUploader from '@/components/form/imgUploader.vue';
import calendarSelect from '@/components/calendar/calendarSelect.vue';
import timeLineTop from '@/components/timeLine/timeLineTop.vue';
import statusTag from '@/components/tag/statusTag.vue';
import { mapState } from 'vuex';
import attendMixin from '@/views/attendance/attendMixin';
export default {
  name: 'clockDetail',
  mixins: [attendMixin],
  components: {
    timeLineTop,
    calendarSelect,
    statusTag,
    imgUploader,
    empty,
  },
  data() {
    return {
      isLoading: false,
      group: {},
      curDate: undefined,
      clock_list: [],
      com_list: [],
      u_key: undefined,
    };
  },
  computed: {
    ...mapState({
      attendType: (state) => state.attendance.attendType,
      attendStatus: (state) => {
        let obj = state.attendance.attendStatus;
        obj['4'] = '补卡';
        return obj;
      },
    }),
    total_list() {
      let arr = [];

      this.clock_list.forEach((item) => {
        item.time =
          (item.type == 1 ? '上班' : '下班') +
          this.$dayjs(item.date).format('HH:mm');
        arr.push(item);
      });

      this.com_list.forEach((item) => {
        item.type = item.com_type;
        item.status = 4; // 已补卡
        item.time =
          (item.type == 1 ? '上班' : '下班') +
          this.$dayjs(item.date).format('HH:mm');
        arr.push(item);
      });

      let tmp = arr.sort((item, item1) => {
        return new Date(item.date) - new Date(item1.date);
      });

      return tmp;
    },
  },
  created() {
    if (this.$route.query) {
      let query = this.$route.query;
      this.u_key = query.user_key;
    }
    if (this.$route.params) {
      let params = this.$route.params;
      this.curDate = this.$dayjs(params.date || new Date()).format(
        'YYYY-MM-DD'
      );
    }
    this.getData();
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$get('/attend_clock/getAttend', {
        filter: false,
        date: this.curDate,
        u_key: this.u_key,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false;
          let { group, clock_list, com_list } = res.data;
          this.group = group;
          this.clock_list = clock_list;
          this.com_list = com_list;

          // this.clock_list = [
          //   {
          //     type: 1,
          //     status: 2,
          //     attend_type: 2,
          //     position: {
          //       lat: '23.130451',
          //       lon: '113.226693',
          //     },
          //     date: '2021-10-15 09:01:00',
          //     img: null,
          //     remark: '\u5907\u6ce8',
          //   },
          //   {
          //     type: 2,
          //     status: 3,
          //     attend_type: 1,
          //     position: {
          //       lat: '23.138526',
          //       lon: '113.224214',
          //     },
          //     date: '2021-10-15 14:01:00',
          //     img: null,
          //     remark: '\u5907\u6ce8',
          //   },
          // ]

          // this.com_list = [
          //   // { date: '2021-10-15 10:00:00', com_type: 1 },
          //   { date: '2021-10-15 18:00:00', com_type: 2 },
          // ]
        }
      });
    },
    changeDate(num) {
      let date;
      if (num == 1) {
        date = this.$dayjs(this.curDate).add(1, 'day');
      } else if (num == -1) {
        date = this.$dayjs(this.curDate).subtract(1, 'day');
      }

      this.curDate = this.$dayjs(date).format('YYYY-MM-DD');
      this.getData();
    },
    isVaild(item, index) {
      if (item.type == 1 && index == 0) return true;
      else if (item.type == 2 && index == this.total_list.length - 1)
        return true;
      else return false;
    },
  },
};
</script>

<style lang="less" scoped>
.main-content {
  padding: 0.5rem;

  .handles {
    display: flex;
    height: 100%;

    .row-between {
      align-items: center;
      padding: 0.5rem 0;
    }

    .handle-btn {
      color: #fff;
      background: @mainColor;
      border-radius: 0.2rem;
      margin-left: 0.5rem;
      padding: 0.2rem;
    }
  }

  /deep/.date-select-item {
    .date {
      color: @titleColor;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .row {
    .label {
      white-space: nowrap;
    }
  }

  .clock-record {
    margin: 1rem 0.5rem;
  }

  .van-tag {
    margin-left: 0.5rem;
  }

  /deep/.time-line-top {
    .time {
      color: @titleColor !important;
    }
  }
}
</style>

<template>
  <div class="main">
    <yy-navbar></yy-navbar>
    <div class="main-content relative" v-myLoad="isLoading">
      <div class="header row-between row-align-center">
        <div class="title">{{ form.name }}考勤规则</div>
        <handle-popver @del="del" @edit="edit"></handle-popver>
      </div>
      <yiyu-detail :formOpts="formOpts">
        <template #members>
          <van-collapse v-model="roleName" accordion>
            <van-collapse-item
              :title="item.role_value + '(' + (item.list.length || 0) + ')'"
              v-for="(item, key) in form.members"
              :key="key"
              :name="key"
            >
              <div
                class="content"
                center
                v-for="(item1, index1) in item.list"
                :key="index1"
                @click="toPage('stat', item1)"
              >
                <info :info="item1" subKey="mc"> </info>
              </div>
            </van-collapse-item>
          </van-collapse>
          <van-divider v-if="!form.members || !form.members.length"
            >暂无成员</van-divider
          >
        </template>
      </yiyu-detail>
    </div>
  </div>
</template>

<script>
import info from '@/bComponents/info.vue';
import handlePopver from '@/components/feedback/handlePopver';
import { Dialog } from 'vant';
import yiyuDetail from '@/components/detail';
import attendMixin from '@/views/attendance/attendMixin';
import { mapState } from 'vuex';

export default {
  name: 'attendGroupDetail',
  mixins: [attendMixin],
  components: {
    handlePopver,
    yiyuDetail,
    info,
  },
  data() {
    return {
      form: {},
      isLoading: false,
      atg_key: undefined,
      roleName: 0,
    };
  },
  computed: {
    ...mapState({
      roles: (state) => state.basic.roles,
    }),
    formOpts() {
      let position = [];
      if (Array.isArray(this.form.position)) {
        this.form.position.forEach((item, index) => {
          position.push(
            {
              label: '地点' + (index + 1),
              val: item.addr,
            },
            {
              label: '距离范围',
              val: item.distance + '米',
            }
          );
        });
      }
      return [
        {
          title: '',
          child: [
            {
              label: '考勤类型',
              val: (this.form.type == 1 ? '固定时间' : '自由时间') + '上下班',
            },
            {
              label: '考勤时间',
              val: this.getTimeRange(this.form.attend_range),
              show: this.form.type == 1,
            },
            {
              label: '休息时间',
              val: this.getTimeRange(this.form.rest_range),
              show: this.form.type == 1,
            },
            {
              label: '允许外勤',
              val: this.form.field == 1 ? '是' : '否',
            },
            {
              label: '组负责人',
              val: this.form.charge_value,
            },
            {
              label: '允许补卡次数',
              val: this.form.compensation,
            },
          ],
        },
        {
          title: '',
          child: position,
        },
        {
          title: '考勤成员',
          slot: 'members',
        },
      ];
    },
  },
  created() {
    if (this.$route.query) {
      let query = this.$route.query;
      this.atg_key = query.atg_key;
      this.getData();
    }
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$get('/attend_group/getGroup', {
        atg_key: this.atg_key,
      }).then((res) => {
        if (res.code === 10000) {
          this.isLoading = false;
          this.form = res.data;
        }
      });
    },
    edit() {
      // if (!this.roles.includes(1, 14)) {
      //   this.$toast.fail('暂无权限');
      //   return;
      // }
      this.$toPage(this, 'attendGroupEdit', this.form);
    },
    del() {
      if (!this.roles.includes(1) && !this.roles.includes(14)) {
        this.$toast.fail('暂无权限');
        return;
      }

      Dialog.confirm({
        title: '是否确认删除',
        message: '删除考勤组后，考勤组内成员则无打卡操作，是否确认删除？',
      })
        .then(() => {
          if (this.postLoading) return;
          this.postLoading = true;
          this.$post('/attend_group/delGroup', {
            atg_key: this.atg_key,
          }).then((res) => {
            this.postLoading = false;
            if (res.code == 10000) {
              this.$toast.success('删除考勤组成功');
              this.$store.dispatch('getAttendInfo');
              this.$go(-1, this);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    toPage(type, item) {
      switch (type) {
        case 'stat':
          this.$toPage(this, 'attendanceStat', item);
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main-content {
  padding: 0.5rem;

  .header {
    margin-bottom: 0.5rem;
    .title {
      font-weight: bold;
      color: @titleColor;
    }
  }

  .info {
    border-bottom: 1px solid @lineColor;
    padding: 0.5rem 0;
  }
}
</style>

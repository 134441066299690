<template>
  <div class="main">
    <yy-navbar></yy-navbar>
    <div class="yiyu-list">
      <div
        class="list-item"
        v-for="(item, index) in list"
        :key="index"
        @click="toPage('detail', item)"
      >
        <div class="list-item-top">
          <div class="left">
            <div class="name">{{ item.name }}</div>
          </div>
          <div class="main-c">></div>
        </div>
        <data-field :data-obj="[getDataObj(item)]" type="row"></data-field>
      </div>
    </div>
    <drag-btn @add="toPage('groupAdd')"></drag-btn>
    <tabbar type="attendance"></tabbar>
  </div>
</template>

<script>
import dragBtn from '@/components/button/dragBtn.vue'
import tabbar from '@/components/tabbar'
import dataField from '@/components/layout/dataField'
export default {
  name: 'attendanceSet',
  components: { tabbar, dragBtn, dataField },
  data() {
    return {
      list: [],
    }
  },
  activated() {
    this.getData()
  },
  methods: {
    toPage(type, item) {
      switch (type) {
        case 'groupAdd':
          this.$toPage(this, 'attendGroupEdit')
          break
        case 'detail':
          this.$toPage(this, 'attendGroupDetail', item)
          break
      }
    },
    getData() {
      this.$get('/attend_group/getGroupList', {}).then((res) => {
        if (res.code == 10000) {
          this.list = res.data
        }
      })
    },
    getDataObj(item) {
      let arr = [
        {
          descText: '人员',
          data: this.getRoleName(item.user),
        },
        {
          descText: '类型',
          data: item.type == 1 ? '固定时间打卡' : '自由打卡',
        },
      ]
      if (item.type == 1) {
        arr.push({
          descText: '固定时间',
          data: item.attend_range?.start + '-' + item.attend_range?.end,
        })
      }
      return arr
    },
    getRoleName(arr) {
      let tmp = []
      if (Array.isArray(arr)) {
        arr.forEach((item) => {
          tmp.push(item.role_value + '(' + item.count + '人)')
        })
      }
      return tmp.join('、')
    },
  },
}
</script>

<style></style>

<template>
  <div class="tabbar-wrapper">
    <van-tabbar
      v-model="active"
      :safe-area-inset-bottom="true"
      :active-color="$mainColor"
      inactive-color="#333"
    >
      <template v-for="(item, index) in filterTabbars">
        <van-tabbar-item
          replace
          :to="item.name"
          v-if="item.show != false"
          :key="index"
          :name="item.name"
        >
          <template #icon>
            <img
              :src="item.src"
              :class="active == item.name ? 'img-filter' : 'gray-filter'"
            />
          </template>
          <span :class="{ active: active == item.name }">{{ item.title }}</span>
        </van-tabbar-item>
      </template>
    </van-tabbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'tabbar',
  data() {
    return {
      active: undefined,
    }
  },
  props: {
    type: {
      type: String,
      default: 'main',
    },
  },
  computed: {
    ...mapState({
      isInCharge: (state) => state.attendance.isInCharge,
    }),
    tabbars() {
      if (this.type == 'attendance') {
        return [
          {
            name: 'attendanceClock',
            title: '打卡',
            src: require('@/assets/icons/home.png'),
            // unSrc: require('@/assets/icons/home_grey.png'),
            page: 'attendanceClock',
          },
          {
            name: 'attendanceApply',
            title: '申请',
            src: require('@/assets/icons/person.png'),
            // unSrc: require('@/assets/icons/person_grey.png'),
            page: 'attendanceApply',
            show: this.isInCharge,
          },
          {
            name: 'attendanceStat',
            title: '统计',
            src: require('@/assets/icons/more.png'),
            // unSrc: require('@/assets/icons/more_grey.png'),
            page: 'attendanceStat',
            show: this.isInCharge,
          },
          {
            name: 'attendanceSet',
            title: '设置',
            src: require('@/assets/icons/set.png'),
            // unSrc: require('@/assets/icons/set_grey.png'),
            page: 'attendanceSet',
          },
        ]
      } else {
        return [
          {
            name: 'FF_home',
            title: '首页',
            src: require('@/assets/icons/home.png'),
            // unSrc: require('@/assets/icons/home_grey.png'),
            page: 'FF_home',
          },
          {
            name: 'FF_produce',
            title: '生产',
            src: require('@/assets/icons/set.png'),
            // unSrc: require('@/assets/icons/set_grey.png'),
            page: 'FF_produce',
          },
          {
            name: 'FF_sales',
            title: '销售',
            src: require('@/assets/icons/person.png'),
            // unSrc: require('@/assets/icons/person_grey.png'),
            page: 'FF_sales',
          },
          {
            name: 'FF_more',
            title: '更多',
            src: require('@/assets/icons/more.png'),
            // unSrc: require('@/assets/icons/more_grey.png'),
            page: 'FF_more',
          },
        ]
      }
    },
    filterTabbars() {
      // 权限控制-根据路由是否加载来判断是否展示
      return this.FILTER_PAGE(this.tabbars)
    },
  },
  created() {
    this.active = this.$route.name
  },
  activated() {
    this.active = this.$route.name
  },
}
</script>

<style lang="less" scoped>
.tabbar-wrapper {
  height: 3.12rem;
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: constant(safe-area-inset-bottom);
  box-sizing: content-box;
}

.van-tabbar {
  border-top: 1px solid #f5f5f5;
  height: 3.12rem;
  z-index: 1001; // 要比van-sticky1000大
}
span.active {
  color: @mainColor;
}

.van-tabbar-item__icon img {
  height: 1.25rem;
}

.gray-filter {
  filter: grayscale(1);
}
</style>

<template>
  <div
    class="yiyu-avator"
    :style="{ width: size, height: size, fontSize: fontSize }"
  >
    {{ String(value).slice(0, 2) }}
  </div>
</template>

<script>
export default {
  name: 'avator',
  props: {
    value: {
      type: String,
    },
    size: {
      type: String,
      default: '3.5rem',
    },
    fontSize: {
      type: String,
      default: '1.2rem',
    },
  },
}
</script>

<style lang="less" scoped>
.yiyu-avator {
  background: @mainColor;
  border-radius: 16%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
}
</style>

<template>
  <div class="time-line-top">
    <div class="time-line-row">
      <div class="left">
        <div class="dot"></div>
        <div class="time">{{ time }}</div>
        <slot name="time"></slot>
      </div>
      <div class="right">
        <slot name="right"></slot>
      </div>
    </div>
    <div class="time-line-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'timeLineTop',
  props: {
    time: {
      type: String,
      default: '0000-00-00 00:00:00',
    },
  },
}
</script>

<style lang="less" scoped>
.time-line-top {
  .dot {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background: @mainColor;
  }
  .time-line-row {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;

      .time {
        margin-left: 0.5rem;
      }
    }
    .right {
      margin-right: 0.8rem;
    }
  }

  .time-line-content {
    padding: 0.8rem;
    border-left: 1px solid @lineColor;
    margin-left: 0.3rem;
  }
}
</style>

<template>
  <div class="main">
    <yy-navbar>
      <template #right v-if="active == 0">
        <download-excel
          :data="exportData"
          :fields="exportHeader"
          :name="exportFileName"
        >
          <div class="main-c">导出报表</div>
        </download-excel>
      </template>
    </yy-navbar>
    <van-tabs v-model="active">
      <van-tab title="公司统计" v-if="IS_HANDLE('ATTENDANCE_COMP_STAT')">
        <compStat
          ref="compStat"
          @tableProps="tableProps"
          @tableList="tableList"
        ></compStat>
      </van-tab>
      <van-tab title="我的统计">
        <div class="main-content relative" v-myLoad="isLoading">
          <section class="header row-between section row-align-center">
            <div class="left title">
              <calendar-select
                :date.sync="curMonth"
                @change="getData"
                type="year-month"
              ></calendar-select>
            </div>
            <div class="right handles">
              <div class="handle-btn" @click="changeDate(-1)">上一月</div>
              <div class="handle-btn" @click="changeDate(1)">下一月</div>
            </div>
          </section>
          <info :info="user_info" sub-key="group">
            <template #right>
              <div
                class="main-c"
                @click="toPage('seeGroupDetail')"
                v-if="user_info.group && user_info.group.atg_key"
              >
                (查看规则)
              </div>
            </template>
          </info>
          <van-collapse v-model="activeName" accordion>
            <van-collapse-item
              :name="item.name"
              v-for="(item, key) in stat"
              :key="key"
            >
              <template #title>
                <div class="row-between">
                  <div class="title">
                    {{ item.label }}
                  </div>
                  <div class="value" :class="{ red: item.red }">
                    {{ item.value ? item.value + (item.extra || '') : '暂无' }}
                  </div>
                </div>
              </template>
              <!-- 平均工时 -->
              <template v-if="activeName == 'average'">
                <section class="stat-item">
                  <div class="text">平均工时 = 打卡工时 / 打卡天数</div>
                  <div
                    class="date-item row-between"
                    v-for="(item, index) in week_list[curAverageIndex]"
                    :key="index"
                    :class="{ 'main-c': item.work_second }"
                    @click="toPage('detail', item)"
                  >
                    <div class="left">{{ item.date }}({{ item.week }})</div>
                    <div class="right">
                      {{
                        isAfterDay(item.date) ? '暂无' : item.total_work_time
                      }}
                    </div>
                  </div>
                  <div class="row-between">
                    <div></div>
                    <div class="handles">
                      <div
                        class="handle-btn"
                        @click="curAverageIndex--"
                        v-show="curAverageIndex > 0"
                      >
                        上一周
                      </div>
                      <div
                        class="handle-btn"
                        @click="curAverageIndex++"
                        v-show="curAverageIndex < week_list.length - 1"
                      >
                        下一周
                      </div>
                    </div>
                  </div>
                </section>
              </template>
              <!-- 出勤天数 -->
              <template v-if="activeName == 'attend'">
                <section class="stat-item">
                  <div
                    class="date-item row-between"
                    v-for="(item, index) in week_list[curAttendIndex]"
                    :key="index"
                    @click="toPage('detail', item)"
                    :class="{ 'main-c': item.total_work_day }"
                  >
                    <div class="left">{{ item.date }}({{ item.week }})</div>
                    <div class="right">
                      <span
                        v-if="String(item.total_work_day).indexOf('0天') !== -1"
                        :class="{ 'main-c': !user_key }"
                        @click.stop="toPage('deal', item)"
                      >
                        0天
                      </span>
                      <span v-else-if="item.total_work_day">
                        {{ item.total_work_day }}</span
                      >
                      <span v-else-if="item.leave_second">已请假</span>
                      <span v-else-if="isAfterDay(item.date)">暂无</span>
                      <span
                        v-else
                        :class="{ 'main-c': !user_key }"
                        @click="toPage('deal', item)"
                      >
                        未打卡
                      </span>
                    </div>
                  </div>
                  <div class="row-between">
                    <div></div>
                    <div class="handles">
                      <div
                        class="handle-btn"
                        @click="curAttendIndex--"
                        v-show="curAttendIndex > 0"
                      >
                        上一周
                      </div>
                      <div
                        class="handle-btn"
                        @click="curAttendIndex++"
                        v-show="curAttendIndex < week_list.length - 1"
                      >
                        下一周
                      </div>
                    </div>
                  </div>
                </section>
              </template>
              <!-- 迟到 -->
              <!-- 早退 -->
              <template v-if="['late', 'early'].includes(activeName)">
                <section class="stat-item">
                  <div
                    class="date-item"
                    v-for="(item, index) in item.list"
                    :key="index"
                  >
                    <div class="row-between">
                      <div class="left">
                        {{ item.date }}({{ item.week }}){{ item.time }}
                      </div>
                      <div class="right">
                        <span
                          :class="{ 'main-c': !user_key }"
                          @click="toPage('deal', item)"
                          v-if="!user_key"
                        >
                          去处理
                        </span>
                      </div>
                    </div>
                    <span>
                      {{ item.text
                      }}{{
                        activeName == 'late'
                          ? convertTime(item.late_second)
                          : convertTime(item.early_second)
                      }}
                    </span>
                  </div>
                  <span v-if="!item.list || !item.list.length">暂无数据</span>
                </section>
              </template>
              <!-- 外勤 -->
              <template v-if="['field'].includes(activeName)">
                <section class="stat-item">
                  <div
                    class="date-item"
                    v-for="(item, index) in item.list"
                    :key="index"
                  >
                    <div class="row-between">
                      <div class="left bold">
                        {{ item.date }}({{ item.week }}){{ item.time }}
                      </div>
                    </div>
                    <span>
                      地点:
                      {{
                        item.position
                          ? '(' +
                            item.position.longitude +
                            ',' +
                            item.position.latitude +
                            ')'
                          : '暂无'
                      }}
                    </span>
                  </div>
                  <span v-if="!item.list || !item.list.length">暂无数据</span>
                </section>
              </template>
              <!-- 请假/补卡/出差 -->
              <template v-if="['off', 'card', 'out'].includes(activeName)">
                <section class="stat-item">
                  <div
                    class="date-item"
                    v-for="(item, index) in item.list"
                    :key="index"
                  >
                    <div class="row-between">
                      <div class="lef bold" v-if="activeName == 'card'">
                        {{ $dayjs(item.date).format('MM/DD HH:mm')
                        }}{{ item.week }}
                      </div>
                      <div class="left bold" v-else>
                        {{ $dayjs(item.start_date).format('MM/DD HH:mm') }}-{{
                          $dayjs(item.end_date).format('MM/DD HH:mm')
                        }}
                      </div>
                      <status-tag
                        :obj="checkStatus"
                        v-model="item.status"
                      ></status-tag>
                    </div>
                    <span v-if="activeName == 'off'">
                      {{ convertTime(item.leave_second) }}
                    </span>
                    <div class="row-between" v-else>
                      <div></div>
                      <div class="main-c" @click="toPage('detail', item)">
                        {{ item.label }}详情>
                      </div>
                    </div>
                  </div>
                  <span v-if="!item.list || !item.list.length">暂无数据</span>
                </section>
              </template>
            </van-collapse-item>
          </van-collapse>
        </div>
      </van-tab>
    </van-tabs>

    <tabbar type="attendance" v-if="!this.user_key"></tabbar>
  </div>
</template>

<script>
import Vue from 'vue';

import JsonExcel from 'vue-json-excel';
Vue.component('downloadExcel', JsonExcel);
import { YDialog } from 'yiyu-vant-ui';
import info from '@/bComponents/info.vue';
import tabbar from '@/components/tabbar';
import calendarSelect from '@/components/calendar/calendarSelect.vue';
import { convertTime } from '@/utils/date.js';
import statusTag from '@/components/tag/statusTag';
import { mapState } from 'vuex';
import compStat from './compStat.vue';
const weekMap = {
  星期一: 1,
  星期二: 2,
  星期三: 3,
  星期四: 4,
  星期五: 5,
  星期六: 6,
  星期日: 7,
};
export default {
  name: 'attendanceStat',
  components: { tabbar, calendarSelect, info, statusTag, compStat },
  data() {
    return {
      active: 0,
      user_key: undefined,
      curMonth: undefined,
      activeName: 'average',
      isLoading: false,
      user_info: {
        nickname: '用户名称',
        user_key: '用户key',
        role: '角色',
        role_value: '角色名',
        group: {
          name: '组名',
          atg_key: '组key',
        },
      },
      clock_list: [
        // 打卡列表
        {
          // 没有考勤
          date: '日期 2021-10-20',
          week: '星期五', // 星期几 星期一 到 星期日
        },
        {
          // 有考勤
          date: '日期 2021-10-20',
          week: '星期五', // 星期几 星期一 到 星期日
          attend_range: {
            // 考勤时间
            end: '下班时间',
            start: '上班时间',
          },
          rest_range: {
            // 休息时间
            end: '开始时间',
            start: '结束时间',
          },
          com_date: '补卡时间',
          com_type: '补卡类型 1 上班， 2 下班',
          start_date: '打卡上班时间',
          end_date: '打卡下班时间',
          start_status: '上班状态 1正常， 2迟到',
          late_second: '迟到时长', // 单位是秒
          end_status: '上班状态 1正常， 2迟到',
          early_second: '早退时长', // 单位是秒
          work_second: '上班时长', // 单位是秒
          start_attend_type: '上班考勤类型 1 内勤， 2 外勤',
          end_attend_type: '下班考勤类型 1 内勤， 2 外勤',
          start_position: {
            // 上班地点
            addr: '地址名称',
            latitude: '纬度',
            longitude: '经度',
          },
          end_position: {
            // 下班地点
            addr: '地址名称',
            latitude: '纬度',
            longitude: '经度',
          },
          leave_second: '请假时长', // 单位是秒
          business_second: '出差时长', // 单位是秒
        },
      ],
      late_list: [
        // 迟到列表
        {
          date: '日期 2021-10-20',
          week: '三', // 星期几 星期一 到 星期日
          time: '时间 9:00',
          late_second: '迟到时长', // 单位是秒
        },
      ],
      late_times: '迟到次数',
      late_total_second: '迟到总时长',
      early_list: [
        {
          // 早退列表
          date: '日期 2021-10-20',
          week: '三',
          time: '时间 9:00',
          early_second: '早退时长', // 单位是秒
        },
      ],
      early_times: '早退次数',
      early_total_second: '早退总时长',
      field_list: [
        // 外勤列表
        {
          date: '日期 2021-10-20',
          week: '二', // 星期几 星期一 到 星期日
          time: '时间 9:00',
          position: {
            latitude: '纬度',
            longitude: '经度',
            addr: '地点名称',
          },
        },
      ],
      field_times: '外勤次数',
      work_total_second: '总工作时长', // 单位是秒
      work_total_days: '总出勤天数',
      leave_times: '请假次数',
      leave_total_second: '总请假时长',
      leave_list: [
        // 请假列表
        {
          start_date: '开始时间',
          end_date: '结束时间',
          status: '申请状态 1 待处理， 2审核中，3通过， 4不通过',
          atp_key: '申请key',
          leave_second: '请假时长', // 单位是秒
        },
      ],
      com_times: '补卡次数',
      com_list: [
        // 补卡列表
        {
          date: '补卡时间',
          status: '申请状态 1 待处理， 2审核中，3通过， 4不通过',
          com_type: '补卡类型 1 上班， 2 下班',
          atp_key: '申请key',
          week: '星期三', // 星期几 星期一 到 星期日
        },
      ],
      bus_times: '出差次数',
      bus_total_second: '出差总时长', // 单位是秒
      bus_list: [
        // 出差列表
        {
          start_date: '开始时间',
          end_date: '结束时间',
          status: '申请状态 1 待处理， 2审核中，3通过， 4不通过',
          atp_key: '申请key',
          bus_second: '出差时长', // 单位是秒
        },
      ],
      week_list: [
        // 平均工时列表
        {
          date: '',
          week: '',
          total_work_time: '',
          total_work_day: '',
        },
      ],
      average_time: undefined, // 平均工时
      curAverageIndex: undefined, // 平均工时，当前第几周
      curAttendIndex: undefined, // 出勤天数，当前第几周
      exportData: [],
      exportHeader: {},
    };
  },
  computed: {
    // exportData() {
    //   return this.$refs.compStat?.tableList
    // },
    // exportHeader() {
    //   let obj = {}
    //   console.log(this.$refs.compStat?.tableProps)
    //   // this.$refs.compStat?.tableProps.forEach((item) => {
    //   //   obj[item.label] = item.prop
    //   // })
    //   return obj
    // },
    exportFileName() {
      return '考勤统计';
    },
    ...mapState({
      checkStatus: (state) => state.attendance.checkStatus,
    }),
    stat() {
      return [
        {
          name: 'average',
          label: '平均工时',
          value: this.average_time,
        },
        {
          name: 'attend',
          label: '出勤天数',
          value: this.work_total_days,
          extra: '天',
        },
        {
          name: 'late',
          label: '迟到',
          value: this.late_times
            ? `${this.late_times || 0}次,${convertTime(this.late_total_second)}`
            : this.late_times,
          red: Boolean(this.late_times),
          list: this.late_list,
          text: '上班迟到',
        },
        {
          name: 'early',
          label: '早退',
          value: this.early_times
            ? `${this.early_times || 0}次,${convertTime(
                this.early_total_second
              )}`
            : undefined,
          red: Boolean(this.early_times),
          list: this.early_list,
          text: '下班早退',
        },
        {
          name: 'field',
          label: '外勤',
          value: this.field_times,
          extra: '次',
          red: Boolean(this.field_times),
          list: this.field_list,
        },
        {
          name: 'off',
          label: '请假',
          value: this.leave_times
            ? `${this.leave_times || 0}次,${convertTime(
                this.leave_total_second
              )}`
            : this.leave_times,
          red: Boolean(this.leave_times),
          list: this.leave_list,
        },
        {
          name: 'card',
          label: '补卡',
          value: this.com_times,
          extra: '次',
          red: Boolean(this.com_times),
          list: this.com_list,
        },
        {
          name: 'out',
          label: '出差',
          value: this.bus_times
            ? `${this.bus_times || 0}次,${convertTime(this.bus_total_second)}`
            : this.bus_times,
          red: Boolean(this.bus_times),
          list: this.bus_list,
        },
      ];
    },
  },
  mounted() {},
  activated() {
    this.curMonth = this.$dayjs(new Date()).format('YYYY-MM');
    this.user_key = this.$route.query?.user_key;

    this.getData();
  },
  methods: {
    tableList(e) {
      this.exportData = e;
    },
    tableProps(e) {
      this.exportHeader = {};
      e.forEach((item) => {
        this.exportHeader[item.label] = item.prop;
      });
    },
    convertTime(val) {
      return convertTime(val);
    },
    changeDate(num) {
      let date;
      if (num == 1) {
        date = this.$dayjs(this.curMonth).add(1, 'month');
      } else if (num == -1) {
        date = this.$dayjs(this.curMonth).subtract(1, 'month');
      }
      this.curMonth = this.$dayjs(date).format('YYYY-MM');
      this.getData();
    },
    getData() {
      this.isLoading = true;
      this.$get('/attend_stat/getAttendStat', {
        month: this.curMonth,
        u_key: this.user_key,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false;
          for (let key in res.data) {
            this[key] = res.data[key];
          }
          this.week_list = [];
          let isWeek = false; // 用来判断是否满了一周
          let aWeek = [];
          this.curAttendIndex = 0;
          this.curAverageIndex = 0;
          this.clock_list.forEach((item) => {
            if (!isWeek) {
              aWeek.push(item);
            } else {
              this.week_list.push(aWeek);
              aWeek = [item];
              isWeek = false;
            }
            if (weekMap[item.week] == 7) {
              isWeek = true;
            }

            item.total_work_time =
              ((Number(item.work_second || 0) / 60 / 60).toFixed(1) * 10) / 10 +
              '时';

            if (item.start_date && item.end_date) {
              item.total_work_day = '1天';

              if (!item.work_second) {
                // 在休息时间完成当天打卡
                item.total_work_day = '0天';
              }
            } else if (item.start_date || item.end_date) {
              item.total_work_day = '0天';
            } else {
              item.total_work_day = undefined;
            }

            if (this.$dayjs(new Date()).isSame(this.$dayjs(item.date), 'day')) {
              let index = this.week_list.length;
              this.curAverageIndex = index;
              this.curAttendIndex = index;
            }
          });

          // if (isWeek) {
          this.week_list.push(aWeek);
          // }

          let average_time = 0;
          if (this.work_total_days) {
            average_time = (this.work_total_second || 0) / this.work_total_days;
          }
          this.average_time =
            ((average_time / 3600).toFixed(1) * 10) / 10 + '时';
        }
      });
    },
    toPage(type, item) {
      switch (type) {
        case 'seeGroupDetail':
          this.$toPage(this, 'attendGroupDetail', {
            atg_key: this.user_info?.group?.atg_key,
          });
          break;
        case 'detail':
          if (['off', 'card', 'out'].includes(this.activeName)) {
            this.$toPage(this, 'applicationDetail', {
              atp_key: item.atp_key,
            });
          } else if (['average', 'attend'].includes(this.activeName)) {
            if (item.total_work_day) {
              this.$toPage(this, 'clockDetail', {
                user_key: this.user_key,
                date: item.date,
              });
            } else if (item.leave_second) {
              this.$toPage(this, 'applicationDetail', {
                atp_key: item.leave_atp_key,
              });
            }
          }
          break;
        case 'deal':
          if (this.user_key) return;
          YDialog({
            type: 'list',
            title: '处理异常',
            data: [
              {
                key: '补卡',
                onConfirm: () => {
                  this.toApply(3, item);
                },
              },
              {
                key: '请假',
                onConfirm: () => {
                  this.toApply(1, item);
                },
              },
              {
                key: '出差',
                onConfirm: () => {
                  this.toApply(2, item);
                },
              },
            ],
          });
          break;
      }
    },
    toApply(type, item) {
      let time, com_type;
      if (this.activeName == 'attend') {
        if (!item.start_date) {
          // time = this.$dayjs(item.attend_range?.start).format('HH:mm')
          time = item.attend_range?.start || '00:00';
          com_type = 1;
        } else {
          // time = this.$dayjs(item.attend_range?.end).format('HH:mm')
          time = item.attend_range?.end || '00:00';
          com_type = 2;
        }
      } else {
        time = item.time;
      }

      this.$toPage(this, 'application', {
        type,
        date: this.$dayjs(item.date).format('YYYY/MM/DD') + ' ' + time,
        com_type,
      });
    },
    // 这一天是否已经到了
    isAfterDay(date) {
      return this.$dayjs(date).isAfter(this.$dayjs(new Date()), 'date');
    },
  },
};
</script>

<style lang="less" scoped>
.main-content {
  padding: 0.5rem;
  .section {
    margin-bottom: 0.5rem;
  }
  .info {
    margin-bottom: 0.5rem;
  }
  .handles {
    display: flex;
    height: 100%;

    .row-between {
      align-items: center;
      padding: 0.5rem 0;
    }

    .handle-btn {
      color: #fff;
      background: @mainColor;
      border-radius: 0.2rem;
      margin-left: 0.5rem;
      padding: 0.2rem;
    }
  }

  /deep/.date-select-item {
    .date {
      color: @titleColor;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .stat-item {
    background: @bgColor;
    padding: 0.5rem;

    .date-item {
      margin: 0.8rem 0;
    }
  }

  .bold {
    color: @titleColor;
    font-weight: bold;
  }
}
</style>

import { Toast } from 'vant';
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      userInfo: (state) => state.basic.userInfo,
      attendInfo: (state) => state.attendance.attendInfo,
      clockTypeObj: (state) => state.attendance.clockType,
      isInCharge: (state) => state.attendance.isInCharge,
      applyType: (state) => state.attendance.applyType,
    }),
  },
  methods: {
    getTimeRange(obj) {
      if (obj) {
        return obj.start + '-' + obj.end;
      } else {
        return undefined;
      }
    },
    geoResultDeal(result = {}) {
      console.error('定位失败', result);
      if (result.message == 'Geolocation permission denied.') {
        Toast.fail('暂无获取定位的权限哦');
      } else if (
        result.message == 'Get geolocation failed.Get ipLocation failed.'
      ) {
        Toast.fail('IP精准定位失败');
      } else {
        Toast.fail('定位失败哦');
      }
    },
    flutterMapDeal(msg) {
      if (window.navigator.userAgent == 'flutterApp') {
        // window.flutter_inappwebview?.callHandler('jsChannel', {
        //     msg,
        //   })
        //   .then(function (result) {
        //     console.log('flutter返回的消息', result, 111)
        //   })
      }
    },
  },
};

<template>
  <div>
    <span class="font-bold p-2">日期选择</span>
    <calendar-select-range
      :start.sync="date_start"
      :end.sync="date_end"
      :maxTime="new Date()"
      @change="handleSearch"
    />

    <el-table
      :data="tableList"
      v-myLoad="isLoading"
      :border="false"
      :key="date_start + date_end"
    >
      <el-table-column
        v-for="item in tableProps"
        class="tableColumn"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        align="center"
        width="120"
      />
    </el-table>
  </div>
</template>

<script>
import ElTableMixin from '@/mixin/ElTableMixin';
import { convertTime } from '@/utils/date';
import calendarSelectRange from '@/components/calendar/calendarSelectRange.vue';
export default {
  name: 'compStat',
  mixins: [ElTableMixin],

  data() {
    return {
      date_start: this.$dayjs(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      ).format('YYYY-MM-DD'),
      date_end: this.$dayjs(new Date()).format('YYYY-MM-DD'),
      isLoading: false,
      tableList: [],
    };
  },
  components: {
    calendarSelectRange,
  },
  mounted() {
    this.getData();
  },
  computed: {
    tableProps() {
      let tableProps = [
        {
          label: '姓名',
          prop: 'nickname',
        },
        {
          label: '实际出勤天数',
          prop: 'work_total_days',
        },
        {
          label: '请假（事假）',
          prop: 'leave_self_times',
        },
        {
          label: '请假（病假）',
          prop: 'leave_ill_times',
        },
        {
          label: '迟到',
          prop: 'late_times',
        },
        {
          label: '早退',
          prop: 'early_times',
        },
        ...(this.tableList?.[0]?.clock_list?.map((item) => ({
          label: item.date,
          prop: item.date,
        })) ?? []),
      ];
      this.$emit('tableProps', tableProps);
      return tableProps;
    },
  },
  watch: {
    tableList(val) {
      this.$emit('tableList', val);
    },
  },
  methods: {
    handleSearch() {
      this.getData();
    },
    getStatus(item) {
      if (item.normal) {
        return '正常';
      } else if (item.leave) {
        return '请假';
      } else {
        let obj = {
          早退: item.early,
          迟到: item.late,
          缺上班卡: item.lack_start,
          缺下班卡: item.lack_end,
        };
        let status = '';
        for (let key in obj) {
          if (obj[key]) {
            status = status + '、' + key;
          }
        }

        return status.substring(1, status.length);
      }
    },
    getData() {
      this.isLoading = true;
      this.$get('attend_stat/getCompAttendStat', {
        date_start: this.date_start,
        date_end: this.date_end,
      }).then((res) => {
        this.isLoading = false;
        if (res.code == 10000) {
          this.tableList = res.data.list.map((item) => {
            let clock_list = {};
            item?.clock_list?.forEach((item1) => {
              clock_list[item1.date] = this.getStatus(item1);
            });
            return {
              ...item,
              leave_self_times: `${item.leave_self_times}次（共${convertTime(
                item.leave_self_second
              )}）`,
              leave_ill_times: `${item.leave_ill_times}次（共${convertTime(
                item.leave_ill_second
              )}）`,
              late_times: `${item.late_times}次（共${convertTime(
                item.late_second
              )}）`,
              early_times: `${item.early_times}次（共${convertTime(
                item.early_second
              )}）`,
              ...clock_list,
            };
          });
        }
      });
    },
  },
};
</script>

<style>
.tableColumn {
  text-align: center;
}
</style>

<template>
  <div class="img_uploader">
    <van-uploader
      :fileList="fileList"
      :after-read="afterRead"
      @delete="deleteImg"
      :max-count="tmpMaxCount"
    />
  </div>
</template>

<script>
import md5 from 'js-md5';
import { deepCopy } from '@/utils/utils';
import { convertToArr } from '@/utils/utils';
import { acceptType } from '@/utils/mixins';
import { curry, filter, isEqual } from 'lodash';
import { compressImg } from '@/utils/compressImg';

export default {
  name: 'cloudImgUpload',
  props: {
    single: {
      type: Boolean,
      default: false,
    },
    img: [Array, String], // sync resultType 为 dataUrl用
    file: [Array, File], // sync resultType 为 file用
    url: [Array, String], // sync resultType 为 file用
    maxCount: String,
    // file 文件类型
    resultType: {
      type: String,
      default: 'dataUrl',
    },
  },
  data() {
    return {
      cos: null,
      fileList: [],
    };
  },
  computed: {
    tmpMaxCount() {
      if (this.single) return 1;
      else return this.maxCount;
    },
  },
  mounted() {
    this.initCos();
  },
  watch: {
    img: {
      immediate: true,
      handler(val) {
        this.imgDeal();
      },
    },
    url: {
      immediate: true,
      handler(val) {
        this.imgDeal();
      },
    },
  },
  methods: {
    imgDeal() {
      if (this.resultType == 'dataUrl') {
        let fileList = convertToArr(this.img);
        let img = [];
        fileList.forEach((item) => {
          img.push({
            url: item,
          });
        });
        this.fileList = img;
      } else if (this.resultType == 'file') {
        let url = convertToArr(this.url);
        let fileList = this.fileList.filter((item) => item.url == undefined);
        url.forEach((item) => {
          fileList.unshift({
            url: item,
          });
        });
        this.fileList = fileList;
      }
      // console.log(this.fileList, 'fileList');
    },
    getMaxCount() {
      if (this.single) return 1;
      else return this.maxCount;
    },
    afterRead(file) {
      if (file?.file) {
        this.uploadFile(file.file).then((res) => {
          // let img = this.img
          // img.push(res)
          // console.log(res);
          let img;
          if (this.single) {
            img = res;
          } else {
            img = this.img || this.url || [];
            img.push(res);
          }
          if (this.resultType == 'dataUrl') {
            this.$emit('update:img', img);
          } else if (this.resultType == 'file') {
            // console.log('fileImg', img);
            this.$emit('update:file', img);
            this.$emit('update:url', img);
          }
        });
      }
    },
    submit() {},
    isBase64(str) {
      if (str === '' || str.trim() === '') {
        return false;
      }
      try {
        return btoa(atob(str)) == str;
      } catch (err) {
        return false;
      }
    },
    initCos() {
      this.$get('/cos/getTempKeys').then((res) => {
        let data = res.data;
        let credentials = data.credentials;
        this.cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: credentials.tmp_secret_id,
              TmpSecretKey: credentials.tmp_secret_key,
              SecurityToken: credentials.session_token,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: data.start_time, // 时间戳，单位秒，如：1580000000
              ExpiredTime: data.expired_time, // 时间戳，单位秒，如：1580000000
              ScopeLimit: true,
            });
          },
        });
      });
    },
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        let name =
          md5(String(new Date().getTime())) + '.' + file.name.split('.')?.reverse()?.[0];
        this.cos.putObject(
          {
            Bucket: 'lutai-1308357780' /* 必须 */,
            Region: 'ap-guangzhou' /* 存储桶所在地域，必须字段 */,
            Key: name /* 必须 */,
            StorageClass: 'STANDARD',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (!err) {
              resolve(
                'https://lutai-1308357780.cos.ap-guangzhou.myqcloud.com/' + name
              );
            } else {
              console.log('err', err);
            }
          }
        );
      });
    },
    deleteImg(e) {
      if (Array.isArray(this.url)) {
        let arr = this.url.filter((item) => item.url == e.url);
        this.$emit('update:url', arr);
      } else {
        this.$emit('update:url', undefined);
      }
      this.afterRead();
    },
  },
};
</script>

<style lang="less" scoped></style>

<template>
  <div class="main">
    <yy-navbar></yy-navbar>
    <div class="main-content relative" v-show="!isLoading">
      <section class="top">
        <info :info="clockInfo" sub-key="mc">
          <template #right>
            <div class="main-c" @click="toPage('apply')" v-if="isInCharge">
              申请
            </div>
          </template>
          <template #bottom>
            <div
              class="main-c"
              @click="toPage('seeGroupDetail')"
              v-if="isInCharge"
            >
              (查看规则)
            </div>
          </template>
        </info>
        <section class="clock-list">
          <div
            class="clock-list-item"
            v-for="(item, index) in total_list"
            :key="index"
          >
            <div class="clock-list-item-top">
              <span> {{ clockTypeObj[item.type] }}</span>
              <!-- <template v-if="attendInfo.type == 1">
                <span v-if="item.type == 1">
                  {{ attendInfo.attend_range && attendInfo.attend_range.start }}
                </span>
                <span v-else>{{
                  attendInfo.attend_range && attendInfo.attend_range.end
                }}</span>
              </template> -->
              <template>
                <span>{{ $dayjs(item.date).format('HH:mm') }}</span>
              </template>
            </div>
            <div class="clock-list-item-bottom">
              <template v-if="item.status == 1">
                <span class="green">正常打卡</span>
              </template>
              <template v-else-if="item.status == 2">
                <span> {{ $dayjs(item.date).format('HH:mm') }}</span>
                <span class="red">迟到</span>
              </template>
              <template v-else-if="item.status == 3">
                <span> {{ $dayjs(item.date).format('HH:mm') }}</span>
                <span class="red">早退</span>
              </template>
              <template v-else-if="item.status == 4">
                <span class="grey">已补卡</span>
              </template>
              <template v-else-if="item.status == 5">
                <span>待打卡</span>
                <span class="main-c" @click="toPage('addApply')">补卡></span>
              </template>
            </div>
          </div>
        </section>

        <empty v-if="!isInCharge">
          <div
            class="main-c"
            @click="toPage('notice')"
            v-if="userInfo.role != 1"
          >
            <p>您暂未有归属考勤组</p>
            <p>点击可发送通知</p>
          </div>
          <div class="main-c" @click="toPage('group')" v-else>
            <p>您暂未有归属考勤组</p>
            <p>点击管理考勤组</p>
          </div>
        </empty>
      </section>
      <section class="do-clock bottom" v-if="isInCharge">
        <div
          class="do-clock relative"
          v-myLoad="geoLoading || !curPosition.addr"
        >
          <div
            class="circle"
            @click="beforeDoClock"
            :class="{ grey: clockFlag == 5 }"
          >
            <span>{{ clockStatusObj[clockStatus] }}</span>
            <span>{{ curTime }}</span>
          </div>
          <div class="row-align-center" @click="changeClockType">
            <img
              class="exchange-icon"
              src="@/assets/icons/exchange.png"
              alt=""
            />
            <span>切换{{ clockType == 1 ? '下班' : '上班' }}打卡</span>
          </div>
          <div class="addr row-align-center" v-if="curPosition.addr">
            <van-badge content="!" v-if="outRange" />
            <van-badge content="√" v-else color="green"></van-badge>
            <!-- <span
              >{{ curPosition.addr
              }}{{ outRange && distance ? '(超' + distance + '米)' : '' }}</span
            > -->
            <span>{{ distance }}米</span>
            <div>({{ this.curPosition.lng + ',' + this.curPosition.lat }})</div>
          </div>
        </div>
        <van-button class="main-c" @click="refreshClick" plain>{{
          window.navigator.userAgent == 'flutterApp' ? '点击定位' : '刷新定位'
        }}</van-button>
      </section>
      <van-dialog
        v-if="dialogShow"
        v-model="dialogShow"
        :title="dialogTitle"
        show-cancel-button
        @confirm="confirmRemark"
      >
        <div class="row">
          <div class="label">拍照</div>
          <img-uploader
            result-type="file"
            ref="imgUploader"
            :file.sync="file"
          ></img-uploader>
        </div>
        <div class="row">
          <div class="label">备注</div>
          <van-field
            type="textarea"
            v-model="remark"
            placeholder="请填写原因"
          ></van-field>
        </div>
      </van-dialog>
    </div>

    <tabbar type="attendance"></tabbar>
  </div>
</template>

<script>
import { Badge } from 'vant';
import imgUploader from '@/components/form/imgUploader.vue';
import empty from '@/components/layout/empty.vue';
import tabbar from '@/components/tabbar';
import info from '@/bComponents/info.vue';
import { getToken, setToken } from '@/utils/cookie';
import store from '@/store';
import { deepCopy } from '@/utils/utils';
import attendMixin from '@/views/attendance/attendMixin';
import { Dialog } from 'vant';

export default {
  name: 'attendanceClock',
  mixins: [attendMixin],
  components: {
    tabbar,
    info,
    empty,
    imgUploader,
    [Badge.name]: Badge,
  },
  data() {
    return {
      c_key: undefined,
      file: [],
      curPosition: {},
      distance: undefined,
      clockStatusObj: {
        // clockType + clockFalg
        12: '上班打卡',
        11: '外勤上班',
        13: '迟到打卡',
        15: '无法打卡',
        22: '下班打卡',
        21: '外勤下班',
        23: '早退打卡',
        24: '更新下班',
        25: '无法打卡',
      },
      clockType: undefined, // 1 上班 2 下班
      // clocFiled: undefined, // 1 可外勤 2 不可外勤
      outRange: true,
      inTimeRange: true,
      timer: undefined,
      geoTimer: undefined,
      curTime: undefined,
      isLoading: false,
      clockLoading: false,
      clock_list: [], // 打卡列表
      com_list: [], // 不可列表
      geoLoading: false,
      remark: undefined,
      dialogShow: false,
      postLoading: false,
      dialogTitle: undefined,
      token: '',
      window: window,
    };
  },
  computed: {
    total_list() {
      let arr = deepCopy(this.clock_list);

      this.com_list.forEach((item) => {
        item.type = item.com_type;
        item.status = 4; // 已补卡
        arr.push(item);
      });
      let tmp = arr.sort((item, item1) => {
        return new Date(item.date) - new Date(item1.date);
      });
      if (tmp.length < 2 && tmp[0]?.type == 2) {
        tmp.unshift({
          type: 1,
          status: 5, // 未打卡
        });
      }

      return tmp;
    },
    clockStatus() {
      return String(this.clockType) + String(this.clockFlag);
    },
    clockFlag() {
      if (this.outRange) {
        if (this.attendInfo.field == 1) {
          return 1; // 外勤
        } else {
          return 5; // 无法打卡
        }
      } else {
        if (this.total_list.length == 2 && this.clockType == 2) {
          return 4; // 更新打卡
        } else if (this.attendInfo.type == 1 && !this.inTimeRange) {
          return 3; // 迟到/早退
        } else {
          return 2;
        }
      }
    },
    clockInfo() {
      let info = deepCopy(this.userInfo);
      info.mc_list = this.attendInfo.mc_list;
      return info;
    },
  },
  watch: {
    curPosition: {
      deep: true,
      handler() {
        this.nestestDisDeal();
      },
    },
    total_list() {
      if (this.total_list.length % 2 != 0) {
        this.clockType = 2;
      } else {
        this.clockType = 1;
      }
    },
  },
  created() {
    if (this.$route.query?.token) {
      setToken(this.$route.query?.token);
    }
    this.token = getToken();
    this.flutterMapDeal('startMap');
    this.getInfo();
    this.geoLoading = true;
    if (sessionStorage['flutter-cur-position']) {
      this.curPosition = JSON.parse(sessionStorage['flutter-cur-position']);
      console.log('获取的本地值', this.curPosition);
      this.geolocation = false;
    }
    this.getGeolocation();
    this.getAttend();
    this.timer = setInterval(() => {
      this.curTime = this.$dayjs(new Date()).format('HH:mm:ss');
      this.inTimeRange = this.isInTimeRange();
    }, 1000);
    this.geoTimer = setInterval(() => {
      this.getGeolocation();
    }, 30 * 1000);
  },
  beforeDestroy() {
    this.flutterMapDeal('endMap');
    window.removeEventListener('message', this.receiveMessage, false);
    if (this.timer) clearInterval(this.timer);
    if (this.geoTimer) clearInterval(this.geoTimer);
  },
  methods: {
    testOpen() {
      // window.open('https://test.yiyu.plus/#/attendanceClock', '_blank');
    },
    flutterListener() {
      if (window.navigator.userAgent == 'flutterApp') {
        window.removeEventListener('message', this.receiveMessage, false);
        window.addEventListener('message', this.receiveMessage, false);
      }
    },
    receiveMessage(data) {
      if (data) {
        if (data.msg == 'curPosition') {
          this.geoLoading = false;
          let result = data.result || {};
          this.curPosition = {
            addr: result.address,
            lat: result.latitude,
            lng: result.longitude,
          };
        }
      }
    },
    toPage(type) {
      switch (type) {
        case 'apply':
          // this.$toPage(this, 'attendanceApply')
          this.$toPage(this, 'application');
          break;
        case 'addApply':
          // 补卡申请
          this.$toPage(this, 'application', {
            type: 3,
          });
          break;
        case 'notice':
          {
            let title = '加入考勤组申请';
            this.toNotice({ title });
          }
          break;
        case 'group':
          this.$toPage(this, 'attendanceSet', {
            route_type: '$replace',
          });
          break;
        case 'seeGroupDetail':
          this.$toPage(this, 'attendGroupDetail', {
            atg_key: this.attendInfo.atg_key,
          });
          break;
      }
    },
    getInfo() {
      this.isLoading = true;
      store.dispatch('getAttendInfo').then((res) => {
        if (res.code == 10000) {
          this.nestestDisDeal();
          this.isLoading = false;
        }
      });
    },
    refreshClick() {
      if (window.navigator.userAgent == 'flutterApp') {
        this.flutterDeal();
      } else {
        this.getGeolocation();
      }
    },
    getGeolocation() {
      if (window.navigator.userAgent == 'flutterApp') {
        return;
      }
      store.dispatch('loadAMap').then((AMap) => {
        console.log(AMap, 99900);
        // https://lbs.amap.com/api/jsapi-v2/documentation#geolocation
        let geolocation = new AMap.Geolocation({
          needAddress: true, // 返回formattedAddress // 是否需要将定位结果进行逆地理编码操作
          extensions: 'all',
          enableHighAccuracy: true, //是否使用高精度定位，默认:false
          timeout: 30000, //超过10秒后停止定位，默认：5s
          zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
          useNative: true, // 是否使用安卓定位sdk用来进行定位
          // noIpLocate: 1, // 1: 手机设备禁止使用IP定位
          GeoLocationFirst: true, // 默认为false，设置为true的时候可以调整PC端为优先使用浏览器定位，失败后使用IP定位
        });
        geolocation.getCurrentPosition((status, result) => {
          this.geoLoading = false;
          if (status == 'complete') {
            console.log(
              'loadAMap结束=====定位来源',
              result.location_type,
              result
            );
            let { formattedAddress, position } = result;
            this.curPosition = {
              addr: formattedAddress || '未知', //TODO: 文档有，但个人版没有返回
              lng: position.lng,
              lat: position.lat,
            };
          } else {
            this.geoResultDeal(result);
          }
        });
      });
    },
    flutterDeal() {
      this.flutterListener();
      if (window.navigator.userAgent == 'flutterApp') {
        window.flutter_inappwebview
          ?.callHandler('jsChannel', {
            msg: this.token,
          })
          .then((result) => {
            this.$toast.fail(result);
            // console.log('flutter返回的消息', result, 111, typeof result);
            // console.log(JSON.stringify(result), result);
            // this.geoLoading = false;
            // if (result) {
            //   this.curPosition = {
            //     addr: result.address,
            //     lat: result.latitude,
            //     lng: result.longitude,
            //   };
            // } else {
            //   this.geoResultDeal();
            // }
          });
        return true;
      } else {
        return false;
      }
    },
    // 单位 米
    getDistance(lng1, lat1, lng2, lat2) {
      var La1 = (lat1 * Math.PI) / 180.0;
      var La2 = (lat2 * Math.PI) / 180.0;
      var La3 = La1 - La2;
      var Lb3 = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
      var s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(La3 / 2), 2) +
              Math.cos(La1) * Math.cos(La2) * Math.pow(Math.sin(Lb3 / 2), 2)
          )
        );
      s = s * 6378.137; //地球半径
      s = Math.round(s * 10000);
      return s;
    },
    nestestDisDeal() {
      let { lng, lat } = this.curPosition;

      if (lng || lat) {
        let dis = [];
        let outRange = true;
        if (Array.isArray(this.attendInfo.position)) {
          this.attendInfo.position.forEach((item) => {
            let { latitude, longitude } = item;
            let distance = this.getDistance(longitude, latitude, lng, lat);
            if (distance <= Number(item.distance || 0)) {
              outRange = false;
            }
            console.log(
              '设置的定位',
              'lng:',
              longitude,
              'lat',
              latitude,
              '距离',
              item.distance
            );
            console.log(
              '当前经纬度',
              'lng:',
              lng,
              'lat',
              lat,
              '距离',
              distance
            );
            dis.push(distance);
          });
        }
        dis.sort((a, b) => a - b);
        this.outRange = outRange;
        this.distance = dis[0];
      }
    },
    beforeDoClock() {
      if (this.clockType == 2 && !this.inTimeRange) {
        // 早退
        Dialog.confirm({
          message: '此时打卡为早退，是否确认打卡',
        })
          .then(() => {
            this.doClock();
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.doClock();
      }
    },
    doClock() {
      if (this.clockFlag == 5) {
        this.$toast.fail('当前无法打卡哦');
        return;
      }
      this.clockLoading = true;
      this.$post('/attend_clock/doClock', {
        type: this.clockType,
        clock_pos: {
          addr: this.curPosition.addr,
          latitude: this.curPosition.lat,
          longitude: this.curPosition.lng,
        },
        remark: this.remark,
      }).then((res) => {
        if (res.code == 10000) {
          this.$toast.success('打卡成功');
          this.c_key = res.data.c_key;
          if (this.clockFlag != 2) {
            // 说明打卡情况
            this.dialogTitle = this.clockStatusObj[this.clockStatus] + '备注';
            this.dialogShow = true;
          }

          this.getAttend();
        }
      });
    },
    getAttend() {
      this.$get('/attend_clock/getAttend', {
        date: this.$dayjs(new Date()).format('YYYY/MM/DD'),
        filter: true,
      }).then((res) => {
        if (res.code == 10000) {
          this.clock_list = res.data.clock_list;
          this.com_list = res.data.com_list;
        } else {
          if (this.timer) clearInterval(this.timer);
          if (this.geoTimer) clearInterval(this.geoTimer);
        }
      });
    },
    changeClockType() {
      this.clockType = this.clockType == 1 ? 2 : 1;
    },
    // 是否在规定的范围内打卡
    isInTimeRange() {
      // if (this.attendInfo) return;
      if (this.attendInfo.type == 2) return true;
      //将时分秒转为时间戳
      function time_to_sec(time) {
        if (time !== null) {
          var s = '';
          var hour = time.split(':')[0];
          var min = time.split(':')[1];
          var sec = time.split(':')[2] || 0;
          s = Number(hour * 3600) + Number(min * 60) + Number(sec);
          return s;
        }
      }

      let { start, end } = this.attendInfo.attend_range;
      let curSec = time_to_sec(this.curTime);
      let startSec = time_to_sec(start);
      let endSec = time_to_sec(end);
      if (startSec > endSec) {
        // 跨夜
        endSec = 24 * 3600;
      }
      console.log(curSec, startSec, endSec);
      if (curSec > startSec && curSec < endSec) {
        return false;
      } else {
        if (this.clockType == 1 && curSec > startSec) {
          return false;
        }
        if (this.clockType == 2 && curSec < endSec) {
          return false;
        }
        return true;
      }
    },
    confirmRemark() {
      if (this.postLoading) return;
      if (!this.file.length && !this.remark) return;
      let promise1, promise2;
      let promise = [];
      this.postLoading = true;
      if (this.file.length) {
        promise1 = this.$refs['imgUploader'].submit(
          this.c_key,
          '100',
          '/attend__file/saveImg'
        );
        promise.push(promise1);
      }

      if (this.remark) {
        promise2 = this.$post('/attend_clock/doClock', {
          remark: this.remark,
          c_key: this.c_key,
        });
        promise.push(promise2);
      }

      Promise.all(promise).then((arr) => {
        let bool = arr.every((item) => item.code == 10000);
        if (bool) {
          this.$toast.success('打卡备注成功');
          this.file = [];
          this.remark = undefined;
        } else {
          this.dialogShow = true;
          this.$toast.fail('打卡备注失败');
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  padding: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  .main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .clock-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.5rem;
      .clock-list-item {
        width: 30%;
        background: @bgColor;
        padding: 0.5rem;
        border-radius: 0.5rem;
        margin: 0.3rem;
        font-size: 0.8rem;
      }
    }
    .do-clock {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1rem 1rem 2rem;
      .circle {
        background: rgba(@mainRgb, 0.6);
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        color: #fff;
        font-weight: bold;
        flex-direction: column;
        .FlexCenter;
        margin: 1rem;

        &.grey {
          background: rgba(16, 16, 16, 0.4);
        }
      }
      .addr {
        font-size: 0.8rem;
      }
    }
  }
}
/deep/.yiyu-empty {
  margin-top: 2.5rem;
}

.exchange-icon {
  width: 1.1rem;
}

.label {
  white-space: nowrap;
  margin-right: 0.5rem;
}
</style>

<template>
  <van-tag :color="defaultColor">{{ obj[value] || '暂无' }}</van-tag>
</template>

<script>
export default {
  name: 'statusTag',
  props: {
    value: {
      type: [String, Number],
      default: '6',
    },
    obj: {
      type: Object,
      default: () => {
        return {
          6: 'tag',
        }
      },
    },
    // 可支持通过 判断 不同状态 给 指定的不同颜色，而不是用的时候要一个个给指定的颜色
    color: {
      type: [String, Object],
      default: () => {
        return {}
      },
    },
  },

  computed: {
    defaultColor() {
      if (typeof this.color == 'string') {
        return this.color
      }

      if (!Object.keys(this.color).length == 0) {
        return this.color[this.value]
      }
      switch (
        String(this.value) &&
        String(this.value).replace(/[^0-9]/gi, '')
      ) {
        case '1':
          return '#d81e06'
        case '2':
          return '#e0620d'
        case '3':
          return '#1196db'
        case '4':
          return '#36ab60'
        case '5':
          return '#db639b'
        case '6':
          return '#707070'
        default:
          return '#fff'
      }
    },
  },
}
</script>

<style></style>

<template>
  <div class="main">
    <yy-navbar tab-title="添加考勤地点">
      <template #right>
        <div class="main-c" @click="confirm">确定</div>
      </template>
    </yy-navbar>
    <div class="main-content">
      <div id="container" tabindex="0"></div>
      <div id="panel">
        <div id="searchBar">
          <input
            id="searchInput"
            placeholder="输入城市名+地名"
            v-model="addr"
          />
        </div>
        <div id="searchResults"></div>
        <div class="addr-list">
          <div
            class="addr-list-item"
            v-for="(item, index) in addr_list"
            :key="index"
          >
            <div class="row-between row-align-center" @click="choseaddr(item)">
              <div class="left">
                <div class="name">{{ item.name }}</div>
                <div class="addr">{{ item.addr }}</div>
              </div>
              <div class="right" v-if="item.id == curAois.id">√</div>
            </div>
          </div>
        </div>
        <div class="radius-input">
          <van-field
            v-model="radius"
            label="打卡范围"
            placeholder="请输入打卡范围"
            @input="setRadius"
          >
            <template #extra>米</template>
          </van-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import attendMixin from '@/views/attendance/attendMixin';
export default {
  name: 'attendanceArea',
  mixins: [attendMixin],
  data() {
    return {
      map: undefined,
      circle: undefined,
      AMap: undefined,
      AMapUI: undefined,
      Loca: undefined,
      PoiPicker: undefined,
      position: {},
      radius: undefined,
      lng: undefined,
      lat: undefined,
      addr: undefined,
      addr_list: [], // 唯一标识 id
      curAois: {},
      curStatus: undefined,
      init: false,
      isUserSelected: false,
    };
  },
  created() {
    this.flutterMapDeal('startMap');
    if (this.$route.params) {
      let { lng, lat, radius } = this.$route.params;
      this.lng = lng;
      this.lat = lat;
      this.radius = radius;
      this.init = true;
      this.curAois = {
        location: {
          lat: this.lat,
          lng: this.lng,
        },
      };

      if (this.lng && this.lat) {
        this.loadMapDeal();
      } else {
        // this.flutterDeal()
      }
    }
  },
  destroyed() {
    this.flutterMapDeal('endMap');
    window.removeEventListener('message', this.receiveMessage, false);
  },
  methods: {
    loadMapDeal() {
      this.$store
        .dispatch('loadAMap')
        .then((AMap) => {
          this.AMap = AMap;
          this.AMapUI = window.AMapUI;
          console.log(1111, this.AMapUI);

          let options = {
            zoom: 16,
          };

          if (this.lng && this.lat) {
            options.center = [this.lng, this.lat];
          }

          this.map = new AMap.Map('container', options);

          this.map.on('complete', () => {
            console.log('地图加载完成！');
            this.addCircle();
          });

          this.addPoiPicker();
          if (this.lng && this.lat) {
            console.log('手动定位');
            this.flutterMapDeal('endMap');
            this.addPositionPicker();
          } else {
            console.log('自动定位');
            this.addGeolocation();
            this.addPositionPicker();
          }
        })
        .catch((e) => {
          console.error('地图加载失败', e);
        });
    },
    flutterListener() {
      if (window.navigator.userAgent == 'flutterApp') {
        window.removeEventListener('message', this.receiveMessage, false);
        window.addEventListener('message', this.receiveMessage, false);
      }
    },
    receiveMessage(data) {
      if (data) {
        if (data.msg == 'curPosition') {
          console.log('收到flutter的值', data.result);
          let curPosition = {
            lat: data.result?.latitude,
            lng: data.result?.longitude,
          };

          console.log('用flutter的值定位', curPosition);
          this.positionDeal(curPosition);
        }
      }
    },
    flutterDeal() {
      // this.flutterListener()
      // if (window.navigator.userAgent == 'flutterApp') {
      //   window.flutter_inappwebview
      //     ?.callHandler('jsChannel', {
      //       msg: 'curPosition',
      //     })
      //     .then((result) => {
      //       if (result) {
      //         if (this.lng && this.lat) {
      //           console.log('不做任何处理')
      //         } else {
      //           console.log('获取到flutter传来的值', result, typeof result)
      //           console.log(JSON.stringify(result), result)
      //           let lat = result.latitude
      //           let lng = result.longitude
      //           this.lat = lat
      //           this.lng = lng
      //           let curPosition = {
      //             lat,
      //             lng,
      //           }
      //           this.curAois = {
      //             location: curPosition,
      //           }
      //           this.loadMapDeal()
      //         }
      //       } else {
      //         this.geoResultDeal()
      //       }
      //     })
      // } else {
      //   this.loadMapDeal()
      // }
    },
    addGeolocation() {
      var geolocation = new this.AMap.Geolocation({
        enableHighAccuracy: true, //是否使用高精度定位，默认:true
        timeout: 30000, //超过10秒后停止定位，默认：5s
        zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
        useNative: true, // 是否使用安卓定位sdk用来进行定位
        // noIpLocate: 1, // 1: 手机设备禁止使用IP定位
        GeoLocationFirst: true, // 默认为false，设置为true的时候可以调整PC端为优先使用浏览器定位，失败后使用IP定位
      });

      geolocation.getCurrentPosition((status, result) => {
        console.log('定位来源', result.location_type, '结果', result);

        if (status == 'complete') {
          this.positionDeal(result.position);
        } else {
          this.geoResultDeal(result);
        }
      });

      this.map.addControl(geolocation);
    },
    // 添加拖拽选址
    addPositionPicker() {
      console.log(' 添加拖拽选址');
      let positionPicker = new this.AMapUI.PositionPicker({
        mode: 'dragMap', //设定为拖拽地图模式，可选'dragMap'、'dragMarker'，默认为'dragMap'
        map: this.map, //依赖地图对象
      });

      positionPicker.on('success', (result) => {
        console.log('拖拽选址成功!!!!', result);
        // if (window.navigator.userAgent != 'flutterApp') {
        this.positionDeal(result.position);
        // }

        this.regeocodeDeal(result.regeocode);
      });

      positionPicker.on('fail', (result) => {
        console.error('添加拖拽选址失败', result);
      });

      positionPicker.start();
    },
    addPoiPicker() {
      console.log('添加搜索');
      this.poiPicker = new this.AMapUI.PoiPicker({
        input: 'searchInput', // 输入框dom对象或者ID
        placeSearchOptions: {
          map: this.map,
          pageSize: 10,
        },
        searchResultsContainer: 'searchResults', // 搜索结果的容器对象或者容器ID
      });

      // 监听poi选中信息
      this.poiPicker.on('poiPicked', (poiResult) => {
        //用户选中的poi点信息
        // {
        //     source: 'search',       //信息来源，search代表搜索服务，suggest代表输入提示
        //     item: {
        //         "id": "B000A8WXY0", //poi点的id
        //         "name": "***",      //名称
        //         "location": lnglat, //经纬度，AMap.LngLat实例,
        //         "address": "***"    //地址信息
        //     }
        // }

        console.log('用户选中的poi点信息', poiResult);
        this.isUserSelected = true;

        let data = poiResult.item;
        this.curAois = {
          id: data.id,
          name: data.name,
          location: data.location,
          addr: data.district + data.address,
        };
        this.addr_list = [this.curAois];
        var source = poiResult.source,
          poi = poiResult.item;

        if (source == 'suggest') {
          console.log('suggest来源的，同样调用搜索');
          // suggest来源的，同样调用搜索

          this.poiPicker.searchByKeyword(poi.name);
          this.poiPicker.clearSearchResults();
        } else {
          console.log('addPoiPicker其他情况！！！！');
        }
      });
    },
    positionDeal(position) {
      console.log('positionDeal', position);
      this.position = position;

      this.lat = position.lat;
      this.lng = position.lng;

      let lnglat = new this.AMap.LngLat(this.lng, this.lat);

      if (!this.map) {
        this.map = this.AMap.Map('container', {
          zoom: 16,
          options: {
            center: [this.lng, this.lat],
          },
        });
      }

      console.log('重新设置圆形中心点', lnglat, this.circle);
      this.circle?.setCenter(lnglat);
    },
    addCircle() {
      // 构造矢量圆形
      this.circle = new this.AMap.Circle({
        radius: this.radius || 0, //半径
        strokeColor: '#3366FF', //边框线颜色
        strokeOpacity: 0.4, //线透明度
        strokeWeight: 1, //线粗细度
        fillColor: '#3366FF', //填充色
        fillOpacity: 0.2, //填充透明度
      });

      this.map.add([this.circle]);
      this.map.setFitView([this.circle]); // 自适应
    },
    // 逆编码地理信息处理
    regeocodeDeal(regeocode) {
      console.log('regeocodeDeal', regeocode);
      if (this.curStatus == 'chose') {
        this.curStatus = undefined;
        return;
      }
      if (this.isUserSelected) {
        this.isUserSelected = false;

        return;
      }
      let addr_list = [];
      addr_list = regeocode.pois;

      let aois = regeocode.aois;

      if (aois.length) {
        // let tmp = aois.filter((item) => { // 暂不删
        let tmp = addr_list.filter((item) => {
          let location = item.location || {};
          let curLocation = this.curAois.location || {};
          return (
            item.id == this.curAois.id ||
            (location.lng == curLocation.lng && location.lat == curLocation.lat)
          );
        })[0];
        if (tmp) {
          this.curAois = tmp;
        } else {
          this.curAois = aois[0];
        }
      }

      if (this.curAois.id) {
        this.addr = this.curAois.name;
        let index = addr_list.findIndex((item) => item.id == this.curAois.id);
        if (index != -1) {
          let tmp = addr_list.splice(index, 1)[0];
          addr_list.unshift(tmp);
        }
      } else {
        this.addr = undefined;
      }
      this.addr_list = addr_list;
    },
    choseaddr(item) {
      console.log('选择地址并设置地图中心点', item);
      if (item.id == this.curAois.id) {
        return;
      }
      this.curStatus = 'chose';
      this.curAois = item;
      this.addr = item.name;
      this.map.setCenter([item.location.lng, item.location.lat]);
    },
    setRadius() {
      if (this.radius) {
        console.log('设置半径', this.radius, this.circle);
        this.circle?.setRadius(this.radius);
      }
    },
    confirm() {
      sessionStorage['yy-cur-map'] = JSON.stringify({
        lng: this.lng,
        lat: this.lat,
        addr: this.addr,
        radius: this.radius,
      });
      this.$go(-1, this);
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  --height: 30vh;
  --barHeight: 3rem;
}

#container {
  width: 100vw;
  height: var(--height);
}

#panel {
  position: absolute;
  top: calc(2.87rem + var(--height));
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  width: 100vw;
  z-index: 999;
  border-left: 1px solid #eaeaea;
  background: #fff;
  padding: 0.5rem;
}

#searchBar {
  height: var(--barHeight);
  background: #ccc;
}

#searchInput {
  width: 100%;
  height: var(--barHeight);
  line-height: 30%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0 5px;
}

#searchResults {
  display: none;
}

.addr-list {
  overflow: auto;
  height: calc(100% - var(--barHeight) * 2);

  &-item {
    padding: 0.5rem;
    border-bottom: 1px solid @lineColor;

    .name {
      color: @titleColor;
      font-weight: 700;
    }

    .addr {
      color: @textColor;
    }

    .right {
      line-height: 100%;
      height: 100%;
      float: right;
      color: @mainColor;
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"info-left",on:{"click":function($event){return _vm.$emit('clickLeft')}}},[_c('yiyu-avator',{attrs:{"value":_vm.datas.nickname && _vm.datas.nickname.slice(-2),"size":"2.8rem","font-size":"1rem"}}),_c('div',{staticClass:"info-txt"},[_c('div',{staticClass:"info-txt-top"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.datas.nickname))]),_vm._l((_vm.datas.roles),function(item,index){return _c('span',{key:index,class:{
            red: item.role == 1,
            purple: item.role == 2,
            green: [5, 6, 7].includes(Number(item.role)),
            orange: [3, 4].includes(Number(item.role)),
            blue: [8, 9].includes(Number(item.role)),
            brown: [10, 11, 12].includes(Number(item.role)),
            tag: true,
          }},[_vm._v(_vm._s(item.role_value))])})],2),_c('div',{staticClass:"info-txt-bottom text-c7"},[_vm._v(" "+_vm._s(_vm.subText)+" ")]),_vm._t("bottom")],2)],1),_vm._t("right")],2)}
var staticRenderFns = []

export { render, staticRenderFns }